<template>
  <v-container fluid fill-height>
    <v-row justify="center" align="center">
      <v-col class="d-flex justify-center" cols="12" sm="8" md="6" lg="4">
        <div class="moving-element"></div>
      </v-col>
    </v-row>
    <v-btn class="mt-5 mb-3" color="primary" href="/">Go to Home</v-btn>

  </v-container>
</template>

<script>
export default {
  name: 'NotFound'
};
</script>

<style scoped>
/* Styling for Page Not Found */
.moving-element {
  width: 100%;
  height: 350px;
  background-image: url('@/assets/images/notFound.jpg');
  background-size: cover;
  background-position: center;
  animation: moveElement 2s infinite alternate;
}

@keyframes moveElement {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}

.v-btn {
  display: block;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
}
</style>
