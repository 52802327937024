import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/homeView.vue";
import Login from "../views/LoginApp.vue";
import VueMeta from "vue-meta";
import NotFound from "@/views/NotFound.vue";
import { appendQueryString } from "@/services/checkApiParams.js";
import axios from 'axios';
// import { component } from "vue/types/umd";


Vue.use(VueRouter);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

const routes = [
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: "/",
    name: "home-view",
    component: () => import("../views/Home.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  // Route Payrolll
  {
    path: "/payroll/card-record",
    name: "card-record",
    component: () => import("../views/payroll/card-record/indexCardRecord.vue"),
    meta: { requiresAuth: true, title: "Card Record | SINTACT V2", parent: true },
  },

  {
    path: "/employee/dashboard",
    name: "payroll-dashboard",
    component: () => import("../views/employee/payrollDashboard.vue"),
    meta: { requiresAuth: true, title: "HR | SINTACT V2", parent: true },
  },
  {
    path: "/employee/index-employee",
    name: "Employee",
    component: () => import("../views/employee/indexEmployee.vue"),
    meta: { requiresAuth: true, title: "Pegawai | SINTACT V2", parent: true },
  },
  {
    path: "/employee/view-employee/:Id",
    name: "empView",
    component: () => import("../views/employee/viewEmployee.vue"),
    meta: { requiresAuth: true, title: "Detail Pegawai | SINTACT V2", parentId: "/employee/index-employee" },
  },
  {
    path: "/attendance/report-salary",
    name: "report-salary",
    component: () => import("../views/attendance/reportSalary.vue"),
    meta: { requiresAuth: true, title: "Report Salary | SINTACT V2", parent: true },
  },
  {
    path: "/attendance/view-report/:id_perusahaan/:bulan/:tahun",
    name: "view-Report",
    component: () => import("../views/attendance/viewReport.vue"),
    meta: { requiresAuth: true, title: "Detail Report | SINTACT V2", parentId: "/attendance/report-salary" },
  },
  {
    path: "/attendance/index-att",
    name: "Attendance",
    component: () => import("../views/attendance/indexAtt.vue"),
    meta: { requiresAuth: true, title: "Attendance | SINTACT V2", parent: true },
  },
  {
    path: "/attendance/view-att/:id_perusahaan/:bulan/:tahun",
    name: "view-Attendance",
    component: () => import("../views/attendance/viewAtt.vue"),
    meta: { requiresAuth: true, title: "Detail Attendance | SINTACT V2", parentId: "/attendance/index-att" },
  },
  
  // {
  //   path: "/so-invoice/index-gaji",
  //   name: "so-invoice-gaji",
  //   component: () => import("../views/penjualan/so-invoice/indexGaji.vue"),
  //   meta: { requiresAuth: true, title: "Tagihan | SINTACT V2" },
  // },
  // {
  //   path: "/so-invoice/update-gaji/:Id",
  //   name: "so-invoice-update-gaji",
  //   component: () => import("../views/penjualan/so-invoice/updateGaji.vue"),
  //   meta: { requiresAuth: true,title: "Detail Tagihan | SINTACT V2",},
  // },
  // Route ALl Laporan
  {
    path: "/report/dashboard",
    name: "report-dashboard",
    component: () => import("../views/laporan/reportDashboard.vue"),
    meta: { requiresAuth: true, title: "Laporan | SINTACT V2", parent: true },
  },
  {
    path: "/laporan/laporan-a",
    name: "laporanA",
    component: () => import("../views/laporan/laporanA.vue"),
    meta: { requiresAuth: true, title: "Laporan | SINTACT V2", parent: true },
  },
  {
    path: "/laporan/laporan-b",
    name: "laporanB",
    component: () => import("../views/laporan/laporanB.vue"),
    meta: { requiresAuth: true, title: "Laporan | SINTACT V2", parent: true },
  },
  {
    path: "/laporan/laporan-a2",
    name: "laporanA2",
    component: () => import("../views/laporan/laporanA2.vue"),
    meta: { requiresAuth: true, title: "Laporan | SINTACT V2", parent: true },
  },
  {
    path: "/laporan/laporan-b2",
    name: "laporanB2",
    component: () => import("../views/laporan/laporanB2.vue"),
    meta: { requiresAuth: true, title: "Laporan | SINTACT V2", parent: true },
  },
  {
    path: "/laporan/posisi-wip",
    name: "posisi-wip",
    component: () => import("../views/laporan/posisiWip.vue"),
    meta: { requiresAuth: true, title: "Posisi WIP | SINTACT V2", parent: true },
  },
  {
    path: "/laporan/laporan-mut-bb",
    name: "LaporanMutBb",
    component: () => import("../views/laporan/laporanMutBb.vue"),
    meta: { requiresAuth: true, title: "Laporan | SINTACT V2", parent: true },
  },
  {
    path: "/laporan/laporan-mut-fg",
    name: "LaporanMutFg",
    component: () => import("../views/laporan/laporanMutFg.vue"),
    meta: { requiresAuth: true, title: "Laporan | SINTACT V2", parent: true },
  },
  {
    path: "/laporan/laporan-mut-eq",
    name: "LaporanMutEq",
    component: () => import("../views/laporan/laporanMutEq.vue"),
    meta: { requiresAuth: true, title: "Laporan | SINTACT V2", parent: true },
  },
  {
    path: "/laporan/laporan-mut-scrap",
    name: "LaporanMutScrap",
    component: () => import("../views/laporan/laporanMutScrap.vue"),
    meta: { requiresAuth: true, title: "Laporan | SINTACT V2", parent: true },
  },
  {
    path: "/laporan/log-act",
    name: "LogAct",
    component: () => import("../views/laporan/logAct.vue"),
    meta: { requiresAuth: true, title: "Laporan | SINTACT V2", parent: true },
  },
  {
    path: "/laporan/business",
    name: "laporanBusiness",
    component: () => import("../views/laporan/grafikBusiness.vue"),
    meta: { requiresAuth: true, title: "Laporan Business | SINTACT V2", parent: true },
  },
  // END route Laporan
  {
    path: "/sales-order/dashboard",
    name: "sales-dashboard",
    component: () => import("../views/penjualan/salesDashboard.vue"),
    meta: { requiresAuth: true, title: "Pesanan | SINTACT V2", parent: true },
  },
  {
    path: "/sales-order/index",
    name: "sales-order",
    component: () => import("../views/penjualan/sales-order/indexApp.vue"),
    meta: { requiresAuth: true, title: "Pesanan | SINTACT V2", parent: true },
  },
  {
    path: "/sales-order/view/:Id",
    name: "so-view",
    component: () => import("../views/penjualan/sales-order/viewApp.vue"),
    meta: { requiresAuth: true, title: "Detail Pesanan | SINTACT V2", parentId: "/sales-order/index" },
  },
  {
    path: "/sales-order/update/:Id",
    name: "so-update",
    component: () => import("../views/penjualan/sales-order/updateApp.vue"),
    meta: { requiresAuth: true, title: "Detail Sales Order | SINTACT V2", parentId: "/sales-order/index" },
  },
  {
    path: "/so-invoice/index",
    name: "so-invoice",
    component: () => import("../views/penjualan/so-invoice/indexApp.vue"),
    meta: { requiresAuth: true, title: "Tagihan Penjualan | SINTACT V2", parent: true },
  },
  {
    path: "/so-invoice/view/:Id",
    name: "so-invoice-view",
    component: () => import("../views/penjualan/so-invoice/viewApp.vue"),
    meta: { requiresAuth: true,title: "Detail Tagihan Penjualan | SINTACT V2", parentId: "/so-invoice/index"},
  },
  {
    path: "/so-invoice/update/:Id",
    name: "so-invoice-update",
    component: () => import("../views/penjualan/so-invoice/updateApp.vue"),
    meta: { requiresAuth: true,title: "Detail Tagihan Penjualan | SINTACT V2", parentId: "/so-invoice/index"},
  },
  {
    path: "/packing-list/index",
    name: "packing-list",
    component: () => import("../views/penjualan/packing-list/indexApp.vue"),
    meta: { requiresAuth: true, title: "Packing List | SINTACT V2", parent: true },
  },
  {
    path: "/packing-list/update/:Id",
    name: "packing-list-update",
    component: () => import("../views/penjualan/packing-list/updateApp.vue"),
    meta: { requiresAuth: true,title: "Packing List | SINTACT V2", parentId: "/packing-list/index" },
  },
  {
    path: "/packing-list/view/:Id",
    name: "packing-list-view",
    component: () => import("../views/penjualan/packing-list/viewApp.vue"),
    meta: { requiresAuth: true,title: "Packing List | SINTACT V2", parentId: "/packing-list/index" },
  },
  {
    path: "/pelanggan/index-pelanggan",
    name: "customer",
    component: () => import("../views/pelanggan/indexPelanggan.vue"),
    meta: { requiresAuth: true, title: "Pelanggan | SINTACT V2", parent: true },
  },
  {
    path: "/pelanggan/view-pelanggan/:Id",
    name: "cust-view",
    component: () => import("../views/pelanggan/viewPelanggan.vue"),
    meta: { requiresAuth: true, title: "Detail Pelanggan | SINTACT V2", parentId: "/pelanggan/index-pelanggan" },
  },
  {
    path: "/pelanggan/index-pemasok",
    name: "supplier",
    component: () => import("../views/pelanggan/indexPemasok.vue"),
    meta: { requiresAuth: true, title: "Pemasok | SINTACT V2", parent: true },
  },
  {
    path: "/pelanggan/view-pemasok/:Id",
    name: "supp-view",
    component: () => import("../views/pelanggan/viewPemasok.vue"),
    meta: { requiresAuth: true, title: "Detail Pemasok | SINTACT V2", parentId: "/pelanggan/index-pemasok" },
  },
  {
    path: "/ppic/dashboard",
    name : "dashboard-ppic",
    component: () => import("../views/ppic/ppicDashboard.vue"),
      meta: { requiresAuth: true, title: "PPIC Dashboard | SINTACT V2", parent: true },
  },
  {
    path: "/po-order/index",
    name: "po-order",
    component: () => import("../views/ppic/po-order/indexApp.vue"),
    meta: { requiresAuth: true, title: "PO Order | SINTACT V2", parent: true },
  },
  {
    path: "/po-order/view/:Id",
    name: "po-order-view",
    component: () => import("../views/ppic/po-order/viewApp.vue"),
    meta: { requiresAuth: true, title: "Detail PO Order | SINTACT V2", parentId: "/po-order/index" },
  },
  {
    path: "/po-order/update/:Id",
    name: "po-order-update",
    component: () => import("../views/ppic/po-order/updateApp.vue"),
    meta: { requiresAuth: true, title: "Detail PO Order | SINTACT V2", parentId: "/po-order/index" },
  },
  {
    path: "/man-bom-mst/index",
    name: "man-bom-mst",
    component: () => import("../views/ppic/man-bom-mst/indexApp.vue"),
    meta: { requiresAuth: true, title: "Bill Of Material | SINTACT V2", parent: true },
  },
  {
    path: "/man-bom-mst/view/:Id",
    name: "bom-view",
    component: () => import("../views/ppic/man-bom-mst/viewApp.vue"),
    meta: { requiresAuth: true, title: "Detail Bill Of Material | SINTACT V2", parentId: "/man-bom-mst/index" },
  },
  {
    path: "/man-bom-mst/update/:Id",
    name: "bom-update",
    component: () => import("../views/ppic/man-bom-mst/updateApp.vue"),
    meta: { requiresAuth: true, title: "Detail Bill Of Material | SINTACT V2", parentId: "/man-bom-mst/index" },
  },
  {
    path: "/man-jo-mst/index",
    name: "man-jo-mst",
    component: () => import("../views/ppic/man-jo-mst/indexApp.vue"),
    meta: { requiresAuth: true, title: "Job Order | SINTACT V2", parent: true },
  },
  {
    path: "/man-jo-mst/view/:Id",
    name: "jo-view",
    component: () => import("../views/ppic/man-jo-mst/viewApp.vue"),
    meta: { requiresAuth: true, title: "Detail Job Order | SINTACT V2", parentId: "/man-jo-mst/index" },
  },
  {
    path: "/man-jo-mst/update/:Id",
    name: "jo-update",
    component: () => import("../views/ppic/man-jo-mst/updateApp.vue"),
    meta: { requiresAuth: true, title: "Detail Job Order | SINTACT V2", parentId: "/man-jo-mst/index" },
  },
  {
    path: "/surat-jalan/index",
    name: "surat-jalan",
    component: () => import("../views/ppic/surat-jalan/indexApp.vue"),
    meta: { requiresAuth: true, title: "Surat Jalan | SINTACT V2" },
  },
  {
    path: "/surat-jalan/view/:Id",
    name: "sj-view",
    component: () => import("../views/ppic/surat-jalan/viewApp.vue"),
    meta: { requiresAuth: true, title: "Detail Surat Jalan | SINTACT V2" },
  },
  {
    path: "/surat-jalan/update/:Id",
    name: "sj-update",
    component: () => import("../views/ppic/surat-jalan/updateApp.vue"),
    meta: { requiresAuth: true, title: "Detail Surat Jalan | SINTACT V2" },
  },
  {
    path: "/purchase-order/dashboard",
    name: "dashboard-purchase",
    component: () => import("../views/purchase-order/purchaseDashboard.vue"),
    meta: { requiresAuth: true, title: "Purchase Dashboards | SINTACT V2", parent: true },
  },
  {
    path: "/purchase-order/index",
    name: "purchase-order",
    component: () => import("../views/purchase-order/indexApp.vue"),
    meta: { requiresAuth: true, title: "Purchase Order | SINTACT V2", parent: true },
  },
  {
    path: "/purchase-order/view/:Id",
    name: "po-view",
    component: () => import("../views/purchase-order/viewApp.vue"),
    meta: { requiresAuth: true, title: "Detail Purchase Order | SINTACT V2", parentId: "/purchase-order/index" },
  },
  {
    path: "/purchase-order/update/:Id",
    name: "po-update",
    component: () => import("../views/purchase-order/updateApp.vue"),
    meta: { requiresAuth: true, title: "Detail Purchase Order | SINTACT V2", parentId: "/purchase-order/index" },
  },
  
  {
    path: "/inventory/dashboard",
    name: "dashboard-inventory",
    component: () => import("../views/inventory/inventoryDashboard.vue"),
    meta: { requiresAuth: true, title: "WH Dashboards | SINTACT V2", parent: true },
  },

  {
    path: "/inventory/report-mutasi",
    name: "report-mutasi",
    component: () => import("../views/inventory/reportMutasi.vue"),
    meta: { requiresAuth: true, title: "Report Mutasi | SINTACT V2", parent: true },
  },

  {
    path: "/barang/index-barang",
    name: "barang",
    component: () => import("../views/inventory/barang/indexApp.vue"),
    meta: { requiresAuth: true, title: "Barang | SINTACT V2", parent: true },
  },
  {
    path: "/barang/view/:Id",
    name: "BarangView",
    component: () => import("../views/inventory/barang/viewApp.vue"),
    meta: { requiresAuth: true, title: "Detail Barang | SINTACT V2", parentId: "/barang/index-barang" },
  },
  {
    path: "/penerimaan-barang/index-material",
    name: "penerimaan-bb",
    component: () => import("../views/inventory/penerimaan/penerimaan-material/indexMaterial.vue"),
    meta: { requiresAuth: true, title: "Material In | SINTACT V2", parent: true },
  },
  {
    path: "/penerimaan-barang/view-material/:Id",
    name: "View-material-in",
    component: () => import("../views/inventory/penerimaan/penerimaan-material/viewMaterial.vue"),
    meta: { requiresAuth: true, title: "Detail Material In | SINTACT V2", parentId: "/penerimaan-barang/index-material" },
  },
  {
    path: "/penerimaan-barang/update-material/:Id",
    name: "update-material-in",
    component: () => import("../views/inventory/penerimaan/penerimaan-material/updateMaterial.vue"),
    meta: { requiresAuth: true, title: "Detail Material In | SINTACT V2", parentId: "/penerimaan-barang/index-material" },
  },
  {
    path: "/pengeluaran-barang/index-material",
    name: "pengeluaran-bb",
    component: () => import("../views/inventory/pengeluaran/pengeluaran-material/indexMaterial.vue"),
    meta: { requiresAuth: true, title: "Material Out | SINTACT V2", parent: true },
  },
  {
    path: "/pengeluaran-barang/view-material/:Id",
    name: "view-material-out",
    component: () => import("../views/inventory/pengeluaran/pengeluaran-material/viewMaterial.vue"),
    meta: { requiresAuth: true, title: "Detail Material Out | SINTACT V2", parentId: "/pengeluaran-barang/index-material" },
  },
  {
    path: "/pengeluaran-barang/update-material/:Id",
    name: "update-material-out",
    component: () => import("../views/inventory/pengeluaran/pengeluaran-material/updateMaterial.vue"),
    meta: { requiresAuth: true, title: "Detail Material Out | SINTACT V2", parentId: "/pengeluaran-barang/index-material" },
  },
  {
    path: "/wip-in/index",
    name: "wip-in",
    component: () => import("../views/inventory/penerimaan/penerimaan-wip/indexWip.vue"),
    meta: { requiresAuth: true, title: "WIP IN | SINTACT V2", parent: true },
  },
  {
    path: "/wip-in/view/:Id",
    name: "wip-in-view",
    component: () => import("../views/inventory/penerimaan/penerimaan-wip/viewWip.vue"),
    meta: { requiresAuth: true, title: "Detail WIP In | SINTACT V2", parentId: "/wip-in/index" },
  },
  {
    path: "/wip-out/index",
    name: "wip-out",
    component: () => import("../views/inventory/pengeluaran/pengeluaran-wip/indexWip.vue"),
    meta: { requiresAuth: true, title: "WIP Out | SINTACT V2", parent: true },
  },
  {
    path: "/wip-out/view/:Id",
    name: "wip-out-view",
    component: () => import("../views/inventory/pengeluaran/pengeluaran-wip/viewWip.vue"),
    meta: { requiresAuth: true, title: "Detail WIP Out | SINTACT V2", parentId: "/wip-out/index" },
  },
  {
    path: "/penerimaan-barang/index-fg",
    name: "fg-in",
    component: () => import("../views/inventory/penerimaan/penerimaan-fg/indexFg.vue"),
    meta: { requiresAuth: true, title: "Finish GOOD In | SINTACT V2", parent: true },
  },
  {
    path: "/penerimaan-barang/view-fg/:Id",
    name: "fg-in-view",
    component: () => import("../views/inventory/penerimaan/penerimaan-fg/viewFg.vue"),
    meta: { requiresAuth: true, title: "Detail Finish GOOD In | SINTACT V2", parentId: "/penerimaan-barang/index-fg" },
  },
  {
    path: "/penerimaan-barang/update-fg/:Id",
    name: "fg-in-update",
    component: () => import("../views/inventory/penerimaan/penerimaan-fg/updateFg.vue"),
    meta: { requiresAuth: true, title: "Detail Finish GOOD In | SINTACT V2", parentId: "/penerimaan-barang/index-fg" },
  },
  {
    path: "/pengeluaran-barang/index-fg",
    name: "fg-out",
    component: () => import("../views/inventory/pengeluaran/pengeluaran-fg/indexFg.vue"),
    meta: { requiresAuth: true, title: "Finish GOOD Out | SINTACT V2", parent: true },
  },
  {
    path: "/pengeluaran-barang/view-fg/:Id",
    name: "fg-out-view",
    component: () => import("../views/inventory/pengeluaran/pengeluaran-fg/viewFg.vue"),
    meta: { requiresAuth: true, title: "Detail Finish GOOD Out | SINTACT V2", parentId: "/pengeluaran-barang/index-fg" },
  },
  {
    path: "/pengeluaran-barang/update-fg/:Id",
    name: "fg-out-update",
    component: () => import("../views/inventory/pengeluaran/pengeluaran-fg/updateFg.vue"),
    meta: { requiresAuth: true, title: "Detail Finish GOOD Out | SINTACT V2", parentId: "/pengeluaran-barang/index-fg" },
  },
  {
    path: "/penerimaan-barang/index-waste",
    name: "wst-in",
    component: () => import("../views/inventory/penerimaan/penerimaan-wst/indexWaste.vue"),
    meta: { requiresAuth: true, title: "Waste In | SINTACT V2", parent: true },
  },
  {
    path: "/penerimaan-barang/view-waste/:Id",
    name: "wst-in-view",
    component: () => import("../views/inventory/penerimaan/penerimaan-wst/viewWaste.vue"),
    meta: { requiresAuth: true, title: "Detail Waste In | SINTACT V2", parentId: "/penerimaan-barang/index-waste" },
  },
  {
    path: "/penerimaan-barang/update-waste/:Id",
    name: "wst-in-update",
    component: () => import("../views/inventory/penerimaan/penerimaan-wst/updateWaste.vue"),
    meta: { requiresAuth: true, title: "Detail Waste In | SINTACT V2", parentId: "/penerimaan-barang/index-waste" },
  },
  {
    path: "/pengeluaran-barang/index-waste",
    name: "wst-out",
    component: () => import("../views/inventory/pengeluaran/pengeluaran-wst/indexWaste.vue"),
    meta: { requiresAuth: true, title: "Waste Out | SINTACT V2", parent: true },
  },
  {
    path: "/pengeluaran-barang/view-waste/:Id",
    name: "wst-out-view",
    component: () => import("../views/inventory/pengeluaran/pengeluaran-wst/viewWaste.vue"),
    meta: { requiresAuth: true, title: "Detail Waste Out | SINTACT V2", parentId: "/pengeluaran-barang/index-waste" },
  },
  {
    path: "/pengeluaran-barang/update-waste/:Id",
    name: "wst-out-update",
    component: () => import("../views/inventory/pengeluaran/pengeluaran-wst/updateWaste.vue"),
    meta: { requiresAuth: true, title: "Detail Waste Out | SINTACT V2", parentId: "/pengeluaran-barang/index-waste" },
  },
  {
    path: "/penerimaan-barang/index-mesin",
    name: "eq-in",
    component: () => import("../views/inventory/penerimaan/penerimaan-eq/indexMesin.vue"),
    meta: { requiresAuth: true, title: "Equipment In | SINTACT V2", parent: true },
  },
  {
    path: "/penerimaan-barang/view-mesin/:Id",
    name: "eq-in-view",
    component: () => import("../views/inventory/penerimaan/penerimaan-eq/viewMesin.vue"),
    meta: { requiresAuth: true, title: "Detail Equipment In | SINTACT V2", parentId: "/penerimaan-barang/index-mesin" },
  },
  {
    path: "/penerimaan-barang/update-mesin/:Id",
    name: "eq-in-update",
    component: () => import("../views/inventory/penerimaan/penerimaan-eq/updateMesin.vue"),
    meta: { requiresAuth: true, title: "Detail Equipment In | SINTACT V2", parentId: "/penerimaan-barang/index-mesin" },
  },  
  {
    path: "/pengeluaran-barang/index-mesin",
    name: "eq-out",
    component: () => import("../views/inventory/pengeluaran/pengeluaran-eq/indexMesin.vue"),
    meta: { requiresAuth: true, title: "Equipment Out | SINTACT V2", parent: true },
  },
  {
    path: "/pengeluaran-barang/view-mesin/:Id",
    name: "eq-out-view",
    component: () => import("../views/inventory/pengeluaran/pengeluaran-eq/viewMesin.vue"),
    meta: { requiresAuth: true, title: "Detail Equipment Out | SINTACT V2", parentId: "/pengeluaran-barang/index-mesin" },
  },
  {
    path: "/pengeluaran-barang/update-mesin/:Id",
    name: "eq-out-update",
    component: () => import("../views/inventory/pengeluaran/pengeluaran-eq/updateMesin.vue"),
    meta: { requiresAuth: true, title: "Detail Equipment Out | SINTACT V2", parentId: "/pengeluaran-barang/index-mesin" },
  },
  {
    path: "/delivery-note/index",
    name: "delivery-note",
    component: () => import("../views/inventory/delivery-note/indexApp.vue"),
    meta: { requiresAuth: true, title: "Delivery Order | SINTACT V2", parent: true },
  },
  {
    path: "/delivery-note/view/:Id",
    name: "dn-view",
    component: () => import("../views/inventory/delivery-note/viewApp.vue"),
    meta: { requiresAuth: true, title: "Detail Delivery Order | SINTACT V2", parentId: "/delivery-note/index" },
  },
  {
    path: "/delivery-note/update/:Id",
    name: "dn-update",
    component: () => import("../views/inventory/delivery-note/updateApp.vue"),
    meta: { requiresAuth: true, title: "Detail Delivery Order | SINTACT V2", parentId: "/delivery-note/index" },
  },
  {
    path: "/received-note/index",
    name: "received-note",
    component: () => import("../views/inventory/received-note/indexApp.vue"),
    meta: { requiresAuth: true, title: "Received Order | SINTACT V2", parent: true },
  },
  {
    path: "/received-note/view/:Id",
    name: "rn-view",
    component: () => import("../views/inventory/received-note/viewApp.vue"),
    meta: { requiresAuth: true, title: "Detail Received Order | SINTACT V2", parentId: "/received-note/index" },
  },
  {
    path: "/received-note/update/:Id",
    name: "rn-update",
    component: () => import("../views/inventory/received-note/updateApp.vue"),
    meta: { requiresAuth: true, title: "Detail Received Order | SINTACT V2", parentId: "/received-note/index" },
  },
  {
    path: "/hutang/index-po-invoice",
    name: "po-invoice",
    component: () => import("../views/hutang/po-invoice/indexApp.vue"),
    meta: { requiresAuth: true, title: "Tagihan Pembelian | SINTACT V2", parent: true },
  },
  {
    path: "/hutang/po-invoice/view/:Id",
    name: "po-inv-view",
    component: () => import("../views/hutang/po-invoice/viewApp.vue"),
    meta: { requiresAuth: true, title: "Tagihan Pembelian | SINTACT V2", parentId: "/hutang/index-po-invoice" },
  },
  {
    path: "/hutang/po-invoice/update/:Id",
    name: "po-inv-update",
    component: () => import("../views/hutang/po-invoice/updateApp.vue"),
    meta: { requiresAuth: true, title: "Tagihan Pembelian | SINTACT V2", parentId: "/hutang/index-po-invoice" },
  },
  {
    path: "/hutang/index-hut-invoice",
    name: "hut-invoice",
    component: () => import("../views/hutang/daftar-hutang/indexApp.vue"),
    meta: { requiresAuth: true, title: "Daftar Hutang | SINTACT V2", parent: true },
  },
  {
    path: "/hutang/index-hut-bayar",
    name: "hut-bayar",
    component: () => import("../views/hutang/pembayaran/indexApp.vue"),
    meta: { requiresAuth: true, title: "Pembayaran Hutang | SINTACT V2", parent: true },
  },
  {
    path: "/hutang/hut-bayar/view/:Id",
    name: "hut-bayar-view",
    component: () => import("../views/hutang/pembayaran/viewApp.vue"),
    meta: { requiresAuth: true, title: "Pembayaran Hutang | SINTACT V2", parentId: "/hutang/index-hut-bayar" },
  },
  {
    path: "/hutang/hut-bayar/update/:Id",
    name: "hut-bayar-update",
    component: () => import("../views/hutang/pembayaran/updateApp.vue"),
    meta: { requiresAuth: true, title: "Pembayaran Hutang | SINTACT V2", parentId: "/hutang/index-hut-bayar" },
  },
  {
    path: "/piutang/index-invoice",
    name: "piut-invoice",
    component: () => import("../views/piutang/invoice/indexApp.vue"),
    meta: { requiresAuth: true, title: "Tagihan Pembelian | SINTACT V2", parent: true },
  },
  {
    path: "/piutang/update-invoice/:Id",
    name: "piut-inv-update",
    component: () => import("../views/piutang/invoice/updateApp.vue"),
    meta: { requiresAuth: true, title: "Pembayaran Piutang | SINTACT V2", parentId: "/piutang/index-invoice" },
  },
  {
    path: "/piutang/index-pembayaran",
    name: "piut-bayar",
    component: () => import("../views/piutang/pembayaran/indexApp.vue"),
    meta: { requiresAuth: true, title: "Pembayaran Piutang | SINTACT V2", parent: true },
  },
  {
    path: "/piutang/view-pembayaran/:Id",
    name: "piut-bayar-view",
    component: () => import("../views/piutang/pembayaran/viewApp.vue"),
    meta: { requiresAuth: true, title: "Pembayaran Piutang | SINTACT V2", parentId: "/piutang/index-pembayaran" },
  },
  {
    path: "/piutang/update-pembayaran/:Id",
    name: "piut-bayar-update",
    component: () => import("../views/piutang/pembayaran/updateApp.vue"),
    meta: { requiresAuth: true, title: "Pembayaran Piutang | SINTACT V2", parentId: "/piutang/index-pembayaran" },
  },
  {
    path: "/harta/index-kategori",
    name: "harta-kategori",
    component: () => import("../views/harta/indexKategori.vue"),
    meta: { requiresAuth: true, title: "Kategori Aset | SINTACT V2", parent: true },
  },
  {
    path: "/harta/index-bangunan",
    name: "harta-bangunan",
    component: () => import("../views/harta/indexBangunan.vue"),
    meta: { requiresAuth: true, title: "Bangunan  | SINTACT V2", parent: true },
  },
  {
    path: "/harta/index-nonbangunan",
    name: "harta-nonbangunan",
    component: () => import("../views/harta/indexNonBangunan.vue"),
    meta: { requiresAuth: true, title: "Non Bangunan  | SINTACT V2", parent: true },
  },
  {
    path: "/harta/index-assets-penyusutan",
    name: "harta-penyusutan",
    component: () => import("../views/harta/penyusutan/indexApp.vue"),
    meta: { requiresAuth: true, title: "Assets Penyusutan | SINTACT V2", parent: true },
  },
  {
    path: "/harta/view-penyusutan/:Id",
    name: "harta-penyusutan-view",
    component: () => import("../views/harta/penyusutan/viewApp.vue"),
    meta: { requiresAuth: true, title: "Assets Penyusutan | SINTACT V2", parentId: "/harta/index-assets-penyusutan" },
  },
  {
    path: "/kas-bank/index",
    name: "kas-bank-index",
    component: () => import("../views/kas-bank/pengaturan/indexApp.vue"),
    meta: { requiresAuth: true, title: "Kas & Bank | SINTACT V2", parent: true },
  },
  {
    path: "/atur-saldo/index",
    name: "atur-saldo-index",
    component: () => import("../views/kas-bank/pengaturan/aturSaldo.vue"),
    meta: { requiresAuth: true, title: "Kas & Bank | SINTACT V2", parent: true },
  },
  {
    path: "/kas-bank/view/:Id",
    name: "kas-bank-view",
    component: () => import("../views/kas-bank/pengaturan/viewApp.vue"),
    meta: { requiresAuth: true, title: "View Kas & Bank | SINTACT V2", parentId: "/kas-bank/index" },
  },
  {
    path: "/kas-bank/index-kartu",
    name: "kas-bank-index-kartu",
    component: () => import("../views/kas-bank/pengaturan/indexKartu.vue"),
    meta: { requiresAuth: true, title: "Kartu | SINTACT V2", parent: true },
  },
  {
    path: "/kas-bank/index-mata-uang",
    name: "kas-bank-index-mata-uang",
    component: () => import("../views/kas-bank/pengaturan/indexMataUang.vue"),
    meta: { requiresAuth: true, title: "Mata Uang | SINTACT V2", parent: true },
  },
  {
    path: "/kas-bank/index-penerimaan",
    name: "kas-bank-index-terima",
    component: () => import("../views/kas-bank/penerimaan/indexApp.vue"),
    meta: { requiresAuth: true, title: "Penerimaan Kas & Bank | SINTACT V2", parent: true },
  },
  {
    path: "/kas-bank/view-terima/:Id",
    name: "kas-bank-view-terima",
    component: () => import("../views/kas-bank/penerimaan/viewApp.vue"),
    meta: { requiresAuth: true, title: "View Penerimaan Kas & Bank | SINTACT V2", parentId: "/kas-bank/index-penerimaan" },
  },
  {
    path: "/kas-bank/update-terima/:Id",
    name: "kas-bank-update-terima",
    component: () => import("../views/kas-bank/penerimaan/updateApp.vue"),
    meta: { requiresAuth: true, title: "Update Penerimaan Kas & Bank | SINTACT V2", parentId: "/kas-bank/index-penerimaan" },
  },
  {
    path: "/kas-bank/index-pengeluaran",
    name: "kas-bank-index-keluar",
    component: () => import("../views/kas-bank/pengeluaran/indexApp.vue"),
    meta: { requiresAuth: true, title: "Pengeluaran Kas & Bank | SINTACT V2", parent: true },
  },
  {
    path: "/kas-bank/view-keluar/:Id",
    name: "kas-bank-view-keluar",
    component: () => import("../views/kas-bank/pengeluaran/viewApp.vue"),
    meta: { requiresAuth: true, title: "View Pengeluaran Kas & Bank | SINTACT V2", parentId: "/kas-bank/index-pengeluaran" },
  },
  {
    path: "/kas-bank/update-keluar/:Id",
    name: "kas-bank-update-keluar",
    component: () => import("../views/kas-bank/pengeluaran/updateApp.vue"),
    meta: { requiresAuth: true, title: "Update Pengeluaran Kas & Bank | SINTACT V2", parentId: "/kas-bank/index-pengeluaran" },
  },
  {
    path: "/kas-bank/index-laporan-kas-harian",
    name: "kas-bank-index-laporan-kas-harian",
    component: () => import("../views/kas-bank/laporan/indexKasHarian.vue"),
    meta: { requiresAuth: true, title: "Laporan Kas Harian | SINTACT V2", parent: true },
  },
  {
    path: "/kas-bank/index-laporan-cash-flow",
    name: "kas-bank-index-laporan-cash-flow",
    component: () => import("../views/kas-bank/laporan/indexCashFlow.vue"),
    meta: { requiresAuth: true, title: "Laporan Cash Flow | SINTACT V2", parent: true },
  },
  {
    path: "/pembukuan/dashboard",
    name: "acc-dashboard",
    component: () => import("../views/pembukuan/accDashboard.vue"),
    meta: { requiresAuth: true, title: "Finance | SINTACT V2", parent: true },
  },
  {
    path: "/pembukuan/index-kode-akun",
    name: "index-kode-akun",
    component: () => import("../views/pembukuan/atur-akun/kode-akun/indexAkun.vue"),
    meta: { requiresAuth: true, title: "Kode Akun | SINTACT V2", parent: true },
  },
  {
    path: "/pembukuan/index-atur-tipe-akun",
    name: "atur-tipe",
    component: () => import("../views/pembukuan/atur-akun/kode-akun/setting/indexTipe.vue"),
    meta: { requiresAuth: true, title: "Atur Tipe Akun | SINTACT V2", parent: true },
  },
  {
    path: "/pembukuan/index-atur-kelompok-akun",
    name: "atur-kelompok",
    component: () => import("../views/pembukuan/atur-akun/kode-akun/setting/indexKelompok.vue"),
    meta: { requiresAuth: true, title: "Atur Kelompok Akun | SINTACT V2", parent: true },
  },
  {
    path: "/pembukuan/index-saldo-akun",
    name: "index-saldo",
    component: () => import("../views/pembukuan/atur-akun/saldo-akun/indexSaldo.vue"),
    meta: { requiresAuth: true, title: "Saldo Akun | SINTACT V2", parent: true },
  },
  {
    path: "/pembukuan/index-atur-hpp",
    name: "atur-hpp",
    component: () => import("../views/pembukuan/atur-akun/kode-akun/setting/indexHpp.vue"),
    meta: { requiresAuth: true, title: "Atur Kelompok Akun | SINTACT V2", parent: true },
  },
  {
    path: "/pembukuan/index-hpp",
    name: "index-hpp",
    component: () => import("../views/pembukuan/hpp/indexApp.vue"),
    meta: { requiresAuth: true, title: "HPP | SINTACT V2", parent: true },
  },
  {
    path: "/pembukuan/index-link",
    name: "index-link",
    component: () => import("../views/pembukuan/atur-akun/akun-link/indexApp.vue"),
    meta: { requiresAuth: true, title: "ACC Link | SINTACT V2", parent: true },
  },
  {
    path: "/pembukuan/index-neraca-percobaan",
    name: "index-neraca-percobaan",
    component: () => import("../views/pembukuan/neraca-percobaan/indexApp.vue"),
    meta: { requiresAuth: true, title: "Neraca Percobaan | SINTACT V2", parent: true },
  },
  {
    path: "/pembukuan/index-neraca",
    name: "index-neraca",
    component: () => import("../views/pembukuan/neraca/indexApp.vue"),
    meta: { requiresAuth: true, title: "Neraca | SINTACT V2", parent: true },
  },
  {
    path: "/pembukuan/index-laporan-rugi-laba",
    name: "index-laporan-rugi-laba",
    component: () =>
      import("../views/pembukuan/laporan-rugi-laba/indexApp.vue"),
    meta: { requiresAuth: true, title: "Laporan Rugi Laba | SINTACT V2", parent: true },
  },
  {
    path: "/pembukuan/index-jurnal",
    name: "acc-jurnal",
    component: () => import("../views/pembukuan/jurnal/indexApp.vue"),
    meta: { requiresAuth: true, title: "Jurnal | SINTACT V2", parent: true },
  },
  
  {
    path: "/pembukuan/view-jurnal/:Id",
    name: "acc-jurnal-view",
    component: () => import("../views/pembukuan/jurnal/viewApp.vue"),
    meta: { requiresAuth: true, title: "Jurnal | SINTACT V2", parentId: "/pembukuan/index-jurnal" },
  },
  {
    path: "/pembukuan/index-buku-besar",
    name: "index-buku-besar",
    component: () => import("../views/pembukuan/buku-besar/indexApp.vue"),
    meta: { requiresAuth: true, title: "Buku Besar | SINTACT V2", parent: true },
  },
  {
    path: "/exim/dashboard",
    name : "dashboard-exim",
    component: () => 
      import("../views/ceisa/eximDashboard.vue"),
      meta: { requiresAuth: true, title: "Exim Dashboard | SINTACT V2", parent: true },
  },
  {
    path: "/ceisa/dokumen-aju",
    name : "ceisa-dokumen-aju",
    component: () => 
      import("../views/ceisa/pengajuan-bc/dokumen-aju/indexApp.vue"),
      meta: { requiresAuth: true, title: "CEISA 40 | SINTACT V2", parent: true },
  },
  // {
  //   path: "/ceisa/view-dokumen-aju/view/:Id",
  //   name : "ceisa-view-dokumen-aju",
  //   component: () => import("../views/ceisa/pengajuan-bc/dokumen-aju/viewApp.vue"),
  // },
  {
    path: "/ceisa/view-dokumen-aju/viewBc20/:Id",
    name : "ceisa-view-bc-20",
    component: () => 
      import("../views/ceisa/pengajuan-bc/dokumen-aju/viewBc20.vue"),
      meta: { requiresAuth: true, title: "View BC 20 | SINTACT V2", parentId: "/ceisa/dokumen-aju" },
  },
  {
    path: "/ceisa/view-dokumen-aju/viewBc23/:Id",
    name : "ceisa-view-bc-23",
    component: () => 
      import("../views/ceisa/pengajuan-bc/dokumen-aju/viewBc23.vue"),
      meta: { requiresAuth: true, title: "View BC 23 | SINTACT V2", parentId: "/ceisa/dokumen-aju" },
  },
  {
    path: "/ceisa/view-dokumen-aju/viewBc25/:Id",
    name : "ceisa-view-bc-25",
    component: () => 
      import("../views/ceisa/pengajuan-bc/dokumen-aju/viewBc25.vue"),
      meta: { requiresAuth: true, title: "View BC 25 | SINTACT V2", parentId: "/ceisa/dokumen-aju" },
  },
  {
    path: "/ceisa/view-dokumen-aju/viewBc27/:Id",
    name : "ceisa-view-bc-27",
    component: () => 
      import("../views/ceisa/pengajuan-bc/dokumen-aju/viewBc27.vue"),
      meta: { requiresAuth: true, title: "View BC 27 | SINTACT V2", parentId: "/ceisa/dokumen-aju" },
  },
  {
    path: "/ceisa/view-dokumen-aju/viewBc261/:Id",
    name : "ceisa-view-bc-261",
    component: () => 
      import("../views/ceisa/pengajuan-bc/dokumen-aju/viewBc261.vue"),
      meta: { requiresAuth: true, title: "View BC 261 | SINTACT V2", parentId: "/ceisa/dokumen-aju" },
  },
  {
    path: "/ceisa/view-dokumen-aju/viewBc262/:Id",
    name : "ceisa-view-bc-262",
    component: () => 
      import("../views/ceisa/pengajuan-bc/dokumen-aju/viewBc262.vue"),
      meta: { requiresAuth: true, title: "View BC 262 | SINTACT V2", parentId: "/ceisa/dokumen-aju" },
  },
  {
    path: "/ceisa/view-dokumen-aju/viewBc30/:Id",
    name : "ceisa-view-bc-30",
    component: () => 
      import("../views/ceisa/pengajuan-bc/dokumen-aju/viewBc30.vue"),
      meta: { requiresAuth: true, title: "View BC 30 | SINTACT V2", parentId: "/ceisa/dokumen-aju" },
  },
  {
    path: "/ceisa/view-dokumen-aju/viewBc40/:Id",
    name : "ceisa-view-bc-40",
    component: () => 
      import("../views/ceisa/pengajuan-bc/dokumen-aju/viewBc40.vue"),
      meta: { requiresAuth: true, title: "View BC 40 | SINTACT V2", parentId: "/ceisa/dokumen-aju" },
  },
  {
    path: "/ceisa/view-dokumen-aju/viewBc41/:Id",
    name : "ceisa-view-bc-41",
    component: () => 
      import("../views/ceisa/pengajuan-bc/dokumen-aju/viewBc41.vue"),
      meta: { requiresAuth: true, title: "View BC 41 | SINTACT V2", parentId: "/ceisa/dokumen-aju" },
  },
  // {
  //   path: "/pengaturan-umum/index-company/",
  //   name : "company",
  //   component: () => 
  //     import("../views/pengaturan-umum/company/indexCompany.vue"),
  //     meta: { requiresAuth: true, title: "Profile Perusahaan | SINTACT V2" },
  // },
  {
    path: "/pengaturan-umum/dashboard",
    name : "dashboard-general-seting",
    component: () => 
      import("../views/pengaturan-umum/setupDashboard.vue"),
      meta: { requiresAuth: true, title: "Setup | SINTACT V2", parent: true },
  },
  {
    path: "/pengaturan-umum/index-user",
    name : "index-user",
    component: () => 
      import("../views/pengaturan-umum/user/indexUser.vue"),
      meta: { requiresAuth: true, title: "Users | SINTACT V2", parent: true },
  },
  {
    path: "/pengaturan-umum/view-user/:Id",
    name : "view-user",
    component: () => 
      import("../views/pengaturan-umum/user/viewApp.vue"),
      meta: { requiresAuth: true, title: "Detail User | SINTACT V2", parentId: "/pengaturan-umum/index-user" },
  },
  {
    path: "/pengaturan-umum/index-hscode",
    name : "index-hscode",
    component: () => 
      import("../views/pengaturan-umum/hs-code/indexApp.vue"),
      meta: { requiresAuth: true, title: "Hs Code | SINTACT V2", parent: true },
  },
  {
    path: "/pengaturan-umum/view-hscode/:Id",
    name : "view-hscode",
    component: () => 
      import("../views/pengaturan-umum/hs-code/viewApp.vue"),
      meta: { requiresAuth: true, title: "Detail Hs Code | SINTACT V2", parentId: "/pengaturan-umum/index-hscode" },
  },
  {
    path: "/pengaturan-umum/index-profil-pengguna",
    name : "index-Profil",
    component: () => 
      import("../views/pengaturan-umum/profil/indexProfil.vue"),
      meta: { requiresAuth: true, title: "User Profile | SINTACT V2", parent: true },
  },
  {
    path: "/pengaturan-umum/index-reff-tarif",
    name : "index-Tarif",
    component: () => 
      import("../views/pengaturan-umum/akuntansi/indexReffTarif.vue"),
      meta: { requiresAuth: true, title: "Referensi Tarif | SINTACT V2", parent: true },
  },
  {
    path: "/pengaturan-umum/index-harta",
    name : "index-set-harta",
    component: () => 
      import("../views/pengaturan-umum/akuntansi/indexHarta.vue"),
      meta: { requiresAuth: true, title: "Referensi Harta | SINTACT V2", parent: true },
  },
  {
    path: "/pengaturan-umum/index-kategori",
    name : "index-set-kategori",
    component: () => import("../views/pengaturan-umum/inventory/indexKategori.vue"),
    meta: { requiresAuth: true, title: "Category | SINTACT V2", parent: true },
  },
  {
    path: "/pengaturan-umum/index-lokasi",
    name : "index-lokasi",
    component: () => import("../views/pengaturan-umum/inventory/indexLokasi.vue"),
      meta: { requiresAuth: true, title: "Locations | SINTACT V2", parent: true },
  },
  {
    path: "/pengaturan-umum/index-transaksi",
    name : "index-set-Transaksi",
    component: () => import("../views/pengaturan-umum/inventory/indexTransaksi.vue"),
      meta: { requiresAuth: true, title: "Transactions | SINTACT V2", parent: true },
  },
  {
    path: "/pengaturan-umum/index-company",
    name : "index-companay",
    component: () => 
      import("../views/pengaturan-umum/profil/indexCompany.vue"),
      meta: { requiresAuth: true, title: "Company Profile | SINTACT V2", parent: true },
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const token = localStorage.getItem("token");
  let roleUser = null;

  if (requiresAuth && !token) {
    next("/login");
  } else if (requiresAuth) {
    try {
      roleUser = await getRoleData();
      const hasAccess = checkAccess(to, roleUser);
      if (hasAccess) {
        setDocumentTitle(to);
        next();
      } else {
        next(false);
      }
    } catch (error) {
      console.log('Error', error);
      next(false);
    }
  } else {
    setDocumentTitle(to);
    next();
  }
});

async function getRoleData() {
  const apiUrl = process.env.VUE_APP_API_URL;
  const baseUrl = `${apiUrl}/user/check-left-menu`;
  const params = { id_user: localStorage.getItem('id') };
  const newUrl = appendQueryString(baseUrl, params);
  const response = await axios.get(newUrl, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  });
  return response.data.data || [];
}

function checkAccess(to, roleUser) {
  const parentRoute = to.matched.find(record => record.meta.parent);
  const parentId = to.meta.parentId;

  if (parentRoute) {
    return roleUser.some(role => role.action2 === parentRoute.path);
  } else if (parentId) {
    return roleUser.some(role => role.action2 === parentId);
  } else {
    return true;
  }
}

function setDocumentTitle(to) {
  Vue.nextTick(() => {
    document.title = to.meta.title || "SINTACT V2";
  });
}

export default router;
